<template>
  <div id="pic-swiper">
    <banner :list="list"></banner>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, nextTick, watch} from "vue";
import banner from "@/components/common/Swiper.vue";
import api from "@/api/axios";

export default defineComponent({
  name: "Index",
  components: {
    banner,
  },
  setup() {
    const list = ref(null);
    api.get("/M/testPic").then((res): void => {
      list.value = res.data.obj.listimg;
    });
    return {
      list,
    };
  }

});
</script>
<style type="text/css">
.home-wapper {
  padding-top: 0px !important;
}
.swiper-loading {
  width: 100%!important;
  height: 100%!important;
  display: block;
  position: relative;
  overflow: hidden;
  position: absolute;
  top: 0;
}
.swiper-content {
  width: 100%!important;
  height: 100%!important;
  display: block;
  position: relative;
  overflow: hidden;
}

.swiper-content img {
  width: 100%!important;
  height: 100%!important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}
</style>

