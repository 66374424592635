
import { defineComponent, onMounted, ref, nextTick, watch} from "vue";
import banner from "@/components/common/Swiper.vue";
import api from "@/api/axios";

export default defineComponent({
  name: "Index",
  components: {
    banner,
  },
  setup() {
    const list = ref(null);
    api.get("/M/testPic").then((res): void => {
      list.value = res.data.obj.listimg;
    });
    return {
      list,
    };
  }

});
